import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import theme from './theme.mp3';
import marriedLife from './marriedLife.mp3';
import source1 from './100/1.jpg';
import source2 from './100/2.jpg'
import source3 from './100/3.JPG'
import source4 from './100/4.jpg'
import source5 from './100/5.jpg'
import source6 from './100/6.JPG'
import source7 from './100/7.JPG'
import source8 from './100/8.JPG'
import source9 from './100/9.jpg'
import source10 from './100/10.jpg'
import source11 from './100/11.JPG'
import source12 from './100/12.JPG'
import source13 from './100/13.jpg'
import source14 from './100/14.PNG'
import source15 from './100/15.PNG'
import source16 from './100/16.PNG'
import source17 from './100/17.PNG'
import source19 from './100/19.PNG'
import source20 from './100/20.PNG'
import source21 from './100/21.JPG'
import source22 from './100/22.JPG'
import source23 from './100/23.jpg'
import source24 from './100/24.mp4'
import source25 from './100/25.JPG'
import source26 from './100/26.PNG'
import source27 from './100/27.PNG'
import source28 from './100/28.PNG'
import source29 from './100/29.PNG'
import source30 from './100/30.PNG'
import source31 from './100/31.PNG'
import source32 from './100/32.PNG'
import source33 from './100/33.PNG'
import source34 from './100/34.jpg'
import source35 from './100/35.jpg'
import source36 from './100/36.jpg'
import source37 from './100/37.JPG'
import source38 from './100/38.JPG'
import source39 from './100/39.JPG'
import source40 from './100/40.JPG'
import source41 from './100/41.JPG'
import source42 from './100/42.JPG'
import source43 from './100/43.JPG'
import source44 from './100/44.JPG'
import source45 from './100/45.JPG'
import source46 from './100/46.JPG'
import source47 from './100/47.JPG'
import source48 from './100/48.jpg'
import source49 from './100/49.jpg'
import source50 from './100/50.JPG'
import source51 from './100/51.JPG'
import source52 from './100/52.JPG'
import source53 from './100/53.JPG'
import source54 from './100/54.jpg'
import source55 from './100/55.jpg'
import source56 from './100/56.jpg'
import source57 from './100/57.PNG'
import source58 from './100/58.jpg'
import source59 from './100/59.JPG'
import source60 from './100/60.JPG'
import source61 from './100/61.jpg'
import source62 from './100/62.JPG'
import source63 from './100/63.JPG'
import source64 from './100/64.jpg'
import source65 from './100/65.JPG'
import source66 from './100/66.JPG'
import source67 from './100/67.jpg'
import source68 from './100/68.JPG'
import source69 from './100/69.MP4'
import source70 from './100/70.jpg'
import source71 from './100/71.jpg'
import source72 from './100/72.jpg'
import source73 from './100/73.jpg'
import source74 from './100/74.jpg'
import source75 from './100/75.jpg'
import source76 from './100/76.JPG'
import source77 from './100/77.JPG'
import source78 from './100/78.JPG'
import source79 from './100/79.JPG'
import source80 from './100/80.jpg'
import source81 from './100/81.JPG'
import source82 from './100/82.JPG'
import source83 from './100/83.JPG'
import source84 from './100/84.jpg'
import source85 from './100/85.JPG'
import source86 from './100/86.JPG'
import source87 from './100/87.JPG'
import source88 from './100/88.JPG'
import source89 from './100/89.JPG'
import source90 from './100/90.JPG'
import source91 from './100/91.mp4'
import source92 from './100/92.PNG'
import source93 from './100/93.PNG'
import source94 from './100/94.PNG'
import source95 from './100/95.PNG'
import source96 from './100/96.PNG'
import source97 from './100/97.PNG'
import source98 from './100/98.PNG'
import source99 from './100/99.png'
import source100 from './100/100.mp4'
import source101 from './100/101.mp4'
import { Button, Typography, SimpleDialog, Dialog, DialogTitle, DialogActions, Alert, Snackbar } from '@mui/material';
// SimpleDialog

function App() {
  const music = marriedLife
  const wordings = [
    "宝贝送我的带头像的啤酒",
    "和宝贝第一次自拍",
    "宝贝的可爱照",
    "和宝贝第一次吃火锅",
    "和宝贝第一次吃火锅2",
    "和宝贝舌吻自拍",
    "和宝贝一起在更衣室干坏事",
    "和宝贝去欢乐谷回家自拍",
    "和宝贝去欢乐谷地铁自拍",
    "不知道在干嘛",
    "和宝贝去洞头海边沙滩",
    "和宝贝在洞头海边摆拍",
    "我牛逼的把车停在路边下来拍照",
    "宝贝尴尬语录",
    "跟宝贝玩游戏，玩一次就不玩了",
    "跟宝贝搞说唱",
    "跟宝贝搞说唱2",
    "跟宝贝去吃七欣天",
    "宝贝语录",
    "刚开始在一起天气好热，现在都4度下雪了",
    "遇到貉，发宝贝康康",
    "想给宝贝炒粉吃",
    "唱个说唱给宝贝",
    "一起看中国新说唱",
    "一起恰鸿姐老火锅",
    "第一次见面的喝茶的地方",
    "约会聊天记录",
    "第一次微信聊天，嘻嘻",
    "小作文2",
    "小作文1",
    "检讨书",
    "宝贝姨妈日记",
    "情侣戒指",
    "宝贝给的飞屋环游记的徽章",
    "最爱用的表情",
    "一起看足球",
    "恰宝贝最爱的螺蛳粉",
    "送宝贝蛋糕",
    "穿宝贝衣服",
    "洞头喝咖啡",
    "情侣头像1",
    "在共青森林公园露营",
    "飞屋环游记手办2",
    "抓两只鸡",
    "宝贝送我的带头像的啤酒2",
    "告白的花",
    "一起恰的生牛肉",
    "敷宝贝的面膜",
    "飞屋环游记手办",
    "去见宝贝爸妈前拍照",
    "共青森林公园归来恰饭",
    "宝贝的手环2",
    "共青森林公园回来车被撞",
    "跟宝贝去买东西前宝贝的美照",
    "宝贝跟其他宝贝",
    "鹤顶山露营拍宝贝",
    "给宝贝的手办",
    "第一次做菜给宝贝吃",
    "情侣头像2",
    "我给宝贝拨的石榴",
    "宝贝的手环",
    "跟宝贝坐地铁",
    "跟宝贝吃韩国菜",
    "跟宝贝坐火车",
    "泸浦肉饭，好恰",
    "鹤顶山露营",
    "钱库的水头排骨",
    "肯德基的玩具",
    "一起做肉饭吃，贼好吃",
    "宝贝做的芝士蛋糕，skr",
    "一起恰顺德菜，这种大排档感觉很good",
    "海边的冷饮，good",
    "第一次见面的地方，非常不错",
    "第一次吃的螺蛳粉，说实话，好吃",
    "宝贝的表情包",
    "宝贝小时候的可爱照",
    "跟宝贝在水头，干坏事",
    "洞头开车贼窄的地方擦到车，一代车神首次事故",
    "宝贝的大屁股头像，想日，才怪",
    "跟宝贝康电影",
    "宝贝的性感照，有点骚，想日",
    "一起玩牵手厨房",
    "一起玩羊了个羊",
    "宝贝做的牛轧糖，SKR",
    "帮宝贝搞电脑",
    "宝贝看我最喜欢的动漫，火影忍者",
    "我的沙雕表情包",
    "搞个宝贝最喜欢吃的软糖",
    "宝贝在医院，给她搞个汤喝喝",
    "宝贝的音乐",
    "给宝贝通讯录啥都记下来",
    "去私人影院干坏事，没干成",
    "宝贝流氓语录",
    "敲一下第一次约会时间",
    "约完会第一次微信聊天，SKR",
    "截个聊天凑100张",
    "一起玩吃鸡，就玩一次就不玩了",
    "宝贝的信，么么哒",
    "宝贝拍的曲奇",
    "一起看欢乐谷的万圣节车队"
  ]
  const list = [
    [source1, 1],
    [source2, 1],
    [source3, 1],
    [source4, 1],
    [source5, 1],
    [source6, 1],
    [source7, 1],
    [source8, 1],
    [source9, 1],
    [source10, 1],
    [source11, 1],
    [source12, 1],
    [source13, 1],
    [source14, 1],
    [source15, 1],
    [source16, 1],
    [source17, 1],
    [source19, 1],
    [source20, 1],
    [source21, 1],
    [source22, 1],
    [source23, 1],
    [source24, 2],
    [source25, 1],
    [source26, 1],
    [source27, 1],
    [source28, 1],
    [source29, 1],
    [source30, 1],
    [source31, 1],
    [source32, 1],
    [source33, 1],
    [source34, 1],
    [source35, 1],
    [source36, 1],
    [source37, 1],
    [source38, 1],
    [source39, 1],
    [source40, 1],
    [source41, 1],
    [source42, 1],
    [source43, 1],
    [source44, 1],
    [source45, 1],
    [source46, 1],
    [source47, 1],
    [source48, 1],
    [source49, 1],
    [source50, 1],
    [source51, 1],
    [source52, 1],
    [source53, 1],
    [source54, 1],
    [source55, 1],
    [source56, 1],
    [source57, 1],
    [source58, 1],
    [source59, 1],
    [source60, 1],
    [source61, 1],
    [source62, 1],
    [source63, 1],
    [source64, 1],
    [source65, 1],
    [source66, 1],
    [source67, 1],
    [source68, 1],
    [source69, 2],
    [source70, 1],
    [source71, 1],
    [source72, 1],
    [source73, 1],
    [source74, 1],
    [source75, 1],
    [source76, 1],
    [source77, 1],
    [source78, 1],
    [source79, 1],
    [source80, 1],
    [source81, 1],
    [source82, 1],
    [source83, 1],
    [source84, 1],
    [source85, 1],
    [source86, 1],
    [source87, 1],
    [source88, 1],
    [source89, 1],
    [source90, 1],
    [source91, 2],
    [source92, 1],
    [source93, 1],
    [source94, 1],
    [source95, 1],
    [source96, 1],
    [source97, 1],
    [source98, 1],
    [source99, 1],
    [source100, 2],
    [source101, 2],
  ]
  const [randomNumber, setRandomNumber] = useState(1)
  const wording = randomNumber && wordings[randomNumber]
  const source = randomNumber && list[randomNumber]
  const [open, setOpen] = useState(false)
  const [used, setUsed] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const anniuversary = 1661529600000
  const day = Math.floor((Date.now() - anniuversary) / (24 * 60 * 60 * 1000))

  return (
    <div style={{ background: "#E0FE69", height: '100vh', alignItems: 'center', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
      <audio style={{ marginBottom: '40px' }} controls autoplay>
        <source src={music} type="audio/mpeg" />
        {/* <source src="horse.mp3" type="audio/mpeg"/> */}
        Your browser does not support the audio element.
      </audio>
      <Love />
      {day >= 100 && <Button onClick={() => {
        const r = Math.floor(Math.random() * 100)
        setRandomNumber(r)
        setUsed([r])
        setOpen(true)
      }} style={{ background: 'pink', color: '#A58ABB' }}>100天的100个回忆</Button>}
      <Typography variant="subtitle1" component="div">
        {/* Selected: {selectedValue} */}
      </Typography>
      <br />

      <Dialog
        onClose={() => { setOpen(false) }}
        open={open}
      >
        <DialogTitle>{wording}</DialogTitle>
        {
          source && source[1] === 1
            ? <img src={source[0]} />
            : <video autoPlay><source src={source[0]} type="video/mp4" /></video>
        }
        <DialogActions>
          <Snackbar
            open={showAlert}
            autoHideDuration={6000}>
            <Alert severity="success">没了，林静雯!</Alert>
          </Snackbar>
          {<Button autoFocus variant="outlined"
            onClick={() => {
              if (used.length >= 100) {
                setShowAlert(true)
                return
              }
              var r = Math.floor(Math.random() * 100)
              while (used.includes(r)) {
                r = Math.floor(Math.random() * 100)
              }
              setUsed(
                [...used, r]
              )
              setRandomNumber(r)
            }}
          >
            下一张
          </Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Love() {

  useEffect(() => {
    const c = document.getElementById("myCanvas");

    const ctx = c.getContext("2d");
    const draw = (p1x, p1y, p2x, p2y, color = 'pink') => {
      ctx.moveTo(p1x, p1y);
      ctx.lineTo(p2x, p2y);
      ctx.strokeStyle = color;
      ctx.stroke();
    }

    const x = (t) => {
      return 16 * Math.sin(t) * Math.sin(t) * Math.sin(t);
    }

    const y = (t) => {
      return 13 * Math.cos(t) - 5 * Math.cos(2 * t) - 2 * Math.cos(3 * t) - Math.cos(4 * t);
    }

    const s_step = 0.1
    for (let s = 0; s < 6; s = s + s_step) {
      const step = 0.1;
      for (let t = 0; t < 8; t = t + step) {
        // const x1 = x(t);
        // const y1 = y(t);
        // const x2 = x(t + step);
        // const y2 = y(t + step);
        setTimeout(() => {
          const x1 = x(t);
          const y1 = y(t);
          const x2 = x(t + step);
          const y2 = y(t + step);
          draw(s * x1 + 200, -1 * s * y1 + 200, s * x2 + 200, -1 * s * y2 + 200)
        }, ((s) * 10 + t) * 2000);
        // draw(s * x1 + 200, -1 * s * y1 + 200, s * x2 + 200, -1 * s * y2 + 200)
      }
    }
    // const s_step = 0.1
    // for (let s = 6; s < 10; s = s + s_step) {
    //   const step = 0.1;
    //   for (let t = 0; t < 8; t = t + step) {
    //     setTimeout(() => {
    //       const x1 = x(t);
    //       const y1 = y(t);
    //       const x2 = x(t + step);
    //       const y2 = y(t + step);
    //       draw(s * x1 + 200, -1 * s * y1 + 200, s * x2 + 200, -1 * s * y2 + 200)
    //     }, ((s - 6) * 10 + t) * 50);


    //   }
    //   if (s > 9.8) {
    //     for (let s = 10; s > 6; s = s - s_step) {
    //       const step = 0.1;
    //       for (let t = 8; t > 0; t = t - step) {
    //         setTimeout(() => {
    //           const x1 = x(t);
    //           const y1 = y(t);
    //           const x2 = x(t + step);
    //           const y2 = y(t + step);
    //           draw(s * x1 + 200, -1 * s * y1 + 200, s * x2 + 200, -1 * s * y2 + 200, '#E0FE69')
    //         }, ((10 - s) * 10 + 8 - t) * 50);
    //       }
    //     }
    //   }
    // }



    ctx.font = "30px Arial";
    ctx.strokeStyle = '#A58ABB';
    ctx.fillStyle = '#A58ABB';
    ctx.fillText("李经国", 30, 60);
    ctx.strokeStyle = 'pink';
    ctx.fillStyle = 'pink';
    ctx.fillText("林静雯", 280, 60);

    ctx.strokeStyle = '#A58ABB';
    ctx.fillStyle = '#A58ABB';
    const anniuversary = 1661529600000
    const day = Math.floor((Date.now() - anniuversary) / (24 * 60 * 60 * 1000))
    ctx.fillText(`在一起${day}天了`, 110, 360);
  }, []);

  return (
    <div>
      <canvas
        id="myCanvas"
        width="400"
        height="400"
        style={{ background: "#E0FE69" }}
      >
        Your browser does not support the HTML canvas tag.
      </canvas>
    </div>
  );
}

export default App;
